<template>
  <div class="text-right">
    <div style="padding: 0.51rem 16px 0.25rem 16px;border-bottom:0.15rem solid #F6F7FB">
      <div class="flexs" style="flex-grow: 1;">
        <van-image class="avatar" :src="userInfo.avatar" error-icon='https://image2.pocketliveapp.com/image/icon/static/def_icon.png' />
        <div class="userInfo">
          <div class="name">{{userInfo.username}}</div>
          <!-- <div class="userid">ID：{{userInfo.id}}</div> -->
          <div class="userid">{{$i18n.locale=='ar'?userInfo.id+":ID":'ID:'+userInfo.id }}</div>
          <div class="flexs "><img style="width: 0.41rem;border-radius: 2px;" :src="userInfo.areaUrl" alt=""><span class="diqus">{{userInfo.area}}</span></div>
        </div>
      </div>
    </div>
    <van-list v-if="transferRecordlist!=''" v-model="loading" :finished="finished" :loading-text="$t('transfer.Loading')" :error.sync="error" :error-text="$t('transfer.error')" @load="getTransferRecord">
      <div class="flexs" v-for="(item, index) in transferRecordlist" :key="index" style="padding: 0.34rem 16px 0 16px;">
        <div class="flexs" style="flex-grow: 1;">
          <img class="zhuanshiimg" src="../../assets/transfer/lszs.png" alt="">
          <div class="userInfo">
            <div class="zhuanshi">{{item.diamond}}</div>
            <div class="shijian">{{item.createTime|formatDate}}</div>
          </div>
        </div>
        <div class="Transfer">
          {{$t('transfer.contact_content33')}}
        </div>
      </div>
    </van-list>
    <div v-else class="norecord">
      <div style="text-align:center">
        <img style="width:2.56rem;height:2.56rem" src="../../assets/transfer/record.png" alt="">
        <div class="noTransfer">{{$t('transfer.contact_content31')}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      userid: '',
      enterIDshow: true,
      bottom: 0,
      userInfo: [],
      userInfos: null,
      token: null,
      uid: null,
      headers: null,
      // token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOREV5IiwieHl6IjoiWmpsalpEUTVZakpqT0daaU5HTmlNV0ZpTnpWbFkyWmlNell3WmpnNVlqVT0iLCJleHAiOjE5MDc3NDM3NDB9.irA-gNwNYSD9s6_BQH2nwiQU8Zprq5BS2nyHhqwIRYY',
      // uid: 412,
      // headers: [
      //   'Content-Type:' + 'application/json; charset=utf-8',
      //   'deviceId:' + 'f9cd49b2c8fb4cb1ab75ecfb360f89b5',
      //   'lang:' + 'zh_CN',
      //   'version:' + '1.0.2',
      //   'os:' + '1',
      //   'model:' + 'M2011K2C',
      //   'fid:' + '0',
      // ],
      transferRecordlist: [],
      loading: false,
      finished: false,
      error: false,
      page: 0,
      size: 10,
    };
  },
  created() {
    // this.transferRecord()
    // this.$completes.loadErudaJs()
    // this.familyPocketInfo()
    //调取原生方法
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
    document.getElementsByTagName("html")[0].style.padding = "0";
    if (this.$route.query.userInfo != undefined) {
      this.userInfo = JSON.parse(decodeURIComponent(this.$route.query.userInfo))
    }
  },
  mounted() {
    // this.$i18n.locale = 'en'
    // document.querySelector('html').setAttribute('lang', this.$i18n.locale);
  },

  filters: {
    //数字格式化
    playvolume: function (arg) {
      if (arg > 1000) {
        return arg.toLocaleString()
      }
      else {
        return arg
      }

    },
    formatDate(time) {
      function add0(m) { return m < 10 ? '0' + m : m }
      var time = new Date(time);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
    },
  },
  watch: {
  },

  methods: {
    getUserInfo(data) {
      this.userInfos = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfos.lang.substring(0, 2)
      let lang = this.userInfos.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      document.title = this.$t('transfer.contact_content10')
      this.transferRecord()
    },

    getTransferRecord() {
      if (this.userInfos != null) {
        this.transferRecord()
      }
    },

    transferRecord() {
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + 'api/trade/addressBook/transferRecord?token=' + that.token + '&uid=' + that.uid + '&page=' + that.page + '&size=' + that.size + '&receiveId=' + that.userInfo.id,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          setTimeout(() => {
            // 加载状态结束
            this.loading = false;
            this.transferRecordlist = this.transferRecordlist.concat(data.data.list)
            this.page = this.page + 1
            //数据全部加载完成
            if (data.data.list == '') {
              this.finished = true;
            }
          }, 200);
        }
        else {
          this.loading = false;
          this.error = true;
          Toast(data.message || this.$t('transfer.error'))
        }
      })
    },
  },
};
</script>
<style>
html[lang="ar"] .text-right {
  direction: rtl;
}

html[lang="zh"] .text-left {
  text-align: left;
}

html[lang="ar"] .userInfo {
  text-align: right;
  padding-left: 0;
  padding-right: 0.2rem;
}

html[lang="ar"] .diqus {
  padding-left: 0;
  padding-right: 0.06rem;
}

html[lang="ar"] .userid {
  direction: ltr;
}

.flexs {
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 1.02rem;
  overflow: hidden;
}

.zhuanshiimg {
  width: 0.63rem;
}

.Transfer {
  color: #000000;
  font-size: 0.256rem;
  font-family: Regular;
}

.userInfo {
  padding-left: 0.2rem;
}

.userInfo .userid {
  color: #999999;
  font-size: 0.188rem;
  font-family: Regular;
  padding-bottom: 0.13rem !important;
  line-height: 1;
}

.userInfo .name {
  color: #010101;
  font-size: 0.27rem;
  font-family: mediums;
  line-height: 1;
  padding-bottom: 0.08rem;
}

.userInfo .diqus {
  font-family: mediums;
  padding-left: 0.06rem;
  font-size: 0.2rem;
}

.userInfo .guoqi {
  border-radius: 2px;
  height: 0.239rem;
}

.shijian {
  color: #b0b6b6;
  font-size: 0.188rem;
  font-family: Regular;
  line-height: 1;
}

.userInfo .zhuanshi {
  color: #000000;
  font-size: 0.256rem;
  font-family: mediums;
  line-height: 1;
  padding-bottom: 0.1rem;
}

/* .van-field__body {
        border-bottom: 1px solid #ccc;
    } */
</style>